import "./App.css";
import { Suspense, useEffect, useState } from "react";
import Header from "./components/UI/Header/Header";
import Footer from "./components/UI/Footer/Footer";
import Sidebar from "./components/UI/Sidebar/Sidebar";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import history from "./utils/history";
import PrivateRoute from "./utils/PrivateRoute";
import { store } from "./store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/UI/Loader/Loader";
import { ErrorBoundary } from "react-error-boundary";
import RenderError from "./components/ErrorPages/RenderError/RenderError";
import Container from "react-bootstrap/Container";
import { useSelector, useDispatch } from "react-redux";
import routes from "./routes";
import AuthProvider, { useAuth } from "./context/AuthContext";
import { socket } from "./socket";
import { rolePermissionUpdated } from "./store/auth.slice";
import RolePermissionUpdateModal from "./components/UI/Modal/RolePermissionUpdateModal";

function ErrorFallback({ error, resetErrorBoundary }) {
  return <RenderError error={error} resetErrorBoundary={resetErrorBoundary} />;
}

function Root() {
  history.navigate = useNavigate();
  history.location = useLocation();
  const { authUser } = useAuth();
  const showSideBar = authUser ? true : false;

  const [isConnected, setIsConnected] = useState(socket.connected);
  const [modalShow, setModalShow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onRolePermissionUpdatedEvent(value) {
      if (authUser && value && authUser.role === value.role) {
        dispatch(rolePermissionUpdated(value.permissions));
        localStorage.setItem("permissions", JSON.stringify(value.permissions));
        setModalShow(true);
      }
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("role-permission-updated", onRolePermissionUpdatedEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("role-permission-updated", onRolePermissionUpdatedEvent);
    };
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="App" id="react-app">
        <Header />

        <Container className={authUser ? "p-0 bg-light" : "p-0 login_bg"} fluid>
          <div className="d-flex justify-content-start align-content-center">
            {showSideBar ? (
              <div style={{ width: "20%" }} className="p-0">
                <Sidebar className="shadow-sm" />
              </div>
            ) : (
              ""
            )}
            <div style={{ width: showSideBar ? "80%" : "100%" }}>
              <Routes>
                {routes.map((route, routeindex) =>
                  route.paths.map((path, index) => (
                    <Route
                      exact
                      path={path}
                      element={
                        route.isPrivate ? (
                          <PrivateRoute canAccess={route.canAccess}>
                            {route.element}
                          </PrivateRoute>
                        ) : (
                          route.element
                        )
                      }
                    />
                  ))
                )}
              </Routes>
            </div>
          </div>
        </Container>

        <ToastContainer />

        <RolePermissionUpdateModal
          modaldata={""}
          show={modalShow}
          onHide={() => {
            window.location.reload();
            setModalShow(false);
          }}
        />

        <Footer />
      </div>
    </ErrorBoundary>
  );
}

function App() {
  return (
    <Suspense fallback={<Loader style={{ height: "100vh", width: "100vw" }} />}>
      <Provider store={store}>
        <AuthProvider>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </AuthProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
