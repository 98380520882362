import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Header.css";
import { Link } from "react-router-dom";
import history from "../../../utils/history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getLocalTime } from "../../../utils/getLocalTime";
import API from "../../../utils/API";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import { useAuth } from "../../../context/AuthContext";

import {
  faBell,
  faLock,
  faQuestion,
  faSignIn,
  faSignOut,
  faUser,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

function Header() {
  const { authUser, authActions } = useAuth();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const getLatestNotifications = async () => {
    const response = await API.get("/api/notifications/latest").catch((e) => e);
    return response.data.data;
  };

  const fetchLatestNotifications = async () => {
    setLoading(true);
    let data = await getLatestNotifications();
    setLoading(false);
    setNotifications(data);
  };

  useEffect(() => {
    if (authUser) {
      fetchLatestNotifications();
    }
  }, []);

  const handleSelect = (eventKey) => {
    if (eventKey === "1.1") {
      history.navigate("/profile");
    } else if (eventKey === "1.2") {
      history.navigate("/change-password");
    } else {
      return authActions.onLogoutClicked();
    }
  };

  /*  const Links = [
    {
      icon: faDashboard,
      title: "Dashboard",
      link: "/dashboard",
    },
  ];

  const showNavlinks = () =>
    Links.map((item) => (
      <Nav.Link as={Link} to={item.link}>
        <FontAwesomeIcon className="me-2" icon={item.icon}></FontAwesomeIcon>
        {item.title}
      </Nav.Link>
    )); */

  const NotificationsDropdown = () => (
    <Dropdown className="me-2 mt-2 align-middle">
      <Dropdown.Toggle
        className="bg-light-red text-danger border-0"
        id="dropdown-notification"
      >
        <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
      </Dropdown.Toggle>
      <Dropdown.Menu
        style={{
          left: "-120px",
          border: "none",
        }}
      >
        <ListGroup
          variant="flush"
          style={{ maxHeight: "300px", overflowY: "scroll" }}
        >
          {notifications.length > 0
            ? notifications.map((notification) => (
                <ListGroup.Item style={{ width: "350px" }}>
                  <b>{notification.event_type.split("_").join(" ")}</b>
                  <p className="mb-0">{notification.notification_text}</p>
                  <small className="text-secondary">
                    {getLocalTime(
                      notification.created_at,
                      "DD-MM-YYYY hh:mm A"
                    )}
                  </small>
                </ListGroup.Item>
              ))
            : ""}
          {notifications.length === 0 ? (
            <ListGroup.Item style={{ width: "350px" }}>
              <div className="text-center mt-2">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  size="2x"
                  className="mb-2"
                ></FontAwesomeIcon>
                <p>No Notifications At this moment</p>
              </div>
            </ListGroup.Item>
          ) : (
            ""
          )}
        </ListGroup>
        {notifications.length > 0 ? (
          <div className="text-center bg-light">
            <Nav.Link
              as={Link}
              to={"/notifications"}
              onClick={() => {
                document.getElementById("dropdown-notification").click();
              }}
            >
              <FontAwesomeIcon className="me-2" icon={faBell}></FontAwesomeIcon>
              See All Notifications
            </Nav.Link>
          </div>
        ) : (
          ""
        )}
      </Dropdown.Menu>
    </Dropdown>
  );

  const AuthNavDropdown = () => (
    <NavDropdown
      title={
        <div className="d-flex justify-content-between align-items-center">
          <div className="me-2">{authUser.full_name}</div>
          <Image
            className="rounded-circle"
            height={40}
            width={40}
            src={
              authUser.profile_photo
                ? authUser.profile_photo
                : "/no_preview.png"
            }
            alt="Profile"
          />
        </div>
      }
      id="nav-dropdown"
    >
      <NavDropdown.Item eventKey="1.1">
        <FontAwesomeIcon className="me-2" icon={faUser}></FontAwesomeIcon>
        Profile
      </NavDropdown.Item>
      <NavDropdown.Item eventKey="1.2">
        <FontAwesomeIcon className="me-2" icon={faLock}></FontAwesomeIcon>
        Change Password
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item className="text-danger" eventKey="1.3">
        <FontAwesomeIcon className="me-2" icon={faSignOut}></FontAwesomeIcon>
        Logout
      </NavDropdown.Item>
    </NavDropdown>
  );

  const loggedInLinks = () =>
    authUser && authUser._id ? (
      <Nav className="ms-auto" onSelect={handleSelect}>
        {NotificationsDropdown()}
        {/* <div
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            borderRight: "2px solid #cfd6de",
            height: "52px",
          }}
        ></div> */}
        {AuthNavDropdown()}
      </Nav>
    ) : (
      ""
    );

  const authLinks = () =>
    authUser === null ? (
      <Nav className="ms-auto">
        <Nav.Link as={Link} to="/login">
          <FontAwesomeIcon className="me-2" icon={faSignIn}></FontAwesomeIcon>
          Login
        </Nav.Link>
        <Nav.Link as={Link} to="/forgot-password">
          <FontAwesomeIcon className="me-2" icon={faQuestion}></FontAwesomeIcon>
          Forgot Password
        </Nav.Link>
      </Nav>
    ) : (
      ""
    );

  return (
    <>
      <header>
        <Navbar
          bg="none"
          style={{ backgroundColor: "transparent", background: "transparent" }}
        >
          <Container fluid>
            <Nav.Link as={Link} to="/">
              <Navbar.Brand className="ms-3">
                <Image
                  className="me-2"
                  src="/logo1.png"
                  alt="Logo"
                  height="18"
                  width="140"
                />
              </Navbar.Brand>
            </Nav.Link>

            {authLinks()}
            {loggedInLinks()}
          </Container>
        </Navbar>
      </header>
    </>
  );
}
export default Header;
