import {
  faDashboard,
  faList,
  faUtensilSpoon,
  faCookie,
  faHeartPulse,
  faBox,
  faCalculator,
} from "@fortawesome/free-solid-svg-icons";

const nutritionistMenu = [
  {
    icon: faDashboard,
    title: "Dashboard",
    links: ["/dashboard"],
  },
  {
    icon: faList,
    title: "Categories",
    links: ["/categories"],
  },
  {
    icon: faHeartPulse,
    title: "Dietary Plans",
    links: ["/dietary-plans"],
  },
  {
    icon: faCalculator,
    title: "Calorie Calculation",
    links: ["/calorie-calculation"],
  },
  {
    icon: faCookie,
    title: "Ingredients",
    links: ["/ingredients"],
  },
  {
    icon: faUtensilSpoon,
    title: "Recipes",
    links: ["/recipes"],
  },
  /* {
    icon: faBowlFood,
    title: "Meals",
    links: ["/meals"],
  }, */
  {
    icon: faBox,
    title: "Packages",
    links: ["/packages"],
  },
];
export default nutritionistMenu;
