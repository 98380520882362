import { toast } from "react-toastify";

export const showSuccessMessage = (title) => {
  toast.success(title, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3200,
  });
};

export const showWarningMessage = (title) => {
  toast.warning(title, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3200,
  });
};

export const showErrorMessage = (title) => {
  toast.error(title, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3200,
  });
};

export const showInfoMessage = (title) => {
  toast.info(title, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3200,
  });
};
