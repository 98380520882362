import React, { useContext, createContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, fetchProfile, logoutUser } from "../store/auth.slice";
import { showSuccessMessage, showErrorMessage } from "../utils/ToasterService";

const AuthContext = createContext();
export default function AuthProvider(props) {
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth.user);
  const authError = useSelector((x) => x.auth.error);
  const permissions = useSelector((x) => x.auth.permissions);

  const actions = {
    onFormSubmit: (data) => {
      if (data) {
        dispatch(
          loginUser({ username: data.username, password: data.password })
        )
          .unwrap()
          .then((originalPromiseResult) => {
            console.log("originalPromiseResult=", originalPromiseResult);
            setTimeout(() => {
              showSuccessMessage("Login Successful");
              dispatch(fetchProfile());
            }, 300);
            //reset();
          })
          .catch((rejectedValueOrSerializedError) => {
            console.log(
              "rejectedValueOrSerializedError=",
              rejectedValueOrSerializedError
            );
            //reset();
          });
      }
    },
    onLogoutClicked: () => {
      dispatch(logoutUser());
    },
  };
  return (
    <AuthContext.Provider
      value={{
        authUser: authUser,
        permissions: permissions,
        authError: authError,
        authActions: actions,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
