import {
  faMessage,
  faDashboard,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const customerSupportMenu = [
  {
    icon: faDashboard,
    title: "Dashboard",
    links: ["/dashboard"],
  },
  {
    icon: faQuestionCircle,
    title: "Ticket Management",
    links: ["/ticket-management"],
  },
  {
    icon: faMessage,
    title: "Chat",
    links: ["/chat/conversations"],
  },
];
export default customerSupportMenu;
