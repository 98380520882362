import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Collapse from "react-bootstrap/Collapse";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import adminMenu from "../../../constants/roleMenu/adminMenu";
import nutritionistMenu from "../../../constants/roleMenu/nutritionistMenu";
import accountManagerMenu from "../../../constants/roleMenu/accountManagerMenu";
import customerSupportMenu from "../../../constants/roleMenu/customerSupportMenu";
import deliveryManagerMenu from "../../../constants/roleMenu/deliveryManagerMenu";
import { useAuth } from "../../../context/AuthContext";
import history from "../../../utils/history";
import { useParams, useLocation } from "react-router-dom";

function Sidebar() {
  const { authUser, permissions } = useAuth();
  history.location = useLocation();

  let selectedMenu = [];
  if (authUser) {
    if (authUser.role === "admin") {
      selectedMenu = adminMenu;
    } else if (authUser.role === "nutritionist") {
      selectedMenu = adminMenu.filter((item) => {
        return permissions.filter((permission) => {
          return (
            permission.name === item.title && permission.is_active === true
          );
        }).length > 0
          ? true
          : false;
      });
    } else if (authUser.role === "customer-support") {
      selectedMenu = adminMenu.filter((item) => {
        return permissions.filter(
          (permission) =>
            permission.name === item.title && permission.is_active === true
        ).length > 0
          ? true
          : false;
      });
    } else if (authUser.role === "delivery-manager") {
      selectedMenu = adminMenu.filter((item) => {
        return permissions.filter(
          (permission) =>
            permission.name === item.title && permission.is_active === true
        ).length > 0
          ? true
          : false;
      });
    } else {
      selectedMenu = adminMenu; /* .filter((item) => {
        return permissions.filter(
          (permission) =>
            permission.name === item.title && permission.is_active === true
        ).length > 0
          ? true
          : false;
      }); */
    }
  }

  const [links, setLinks] = useState(selectedMenu);

  const showNavlinks = () =>
    links.map((item, index) =>
      item.menu ? (
        <>
          <Nav.Link
            key={"menu-item-" + index}
            eventKey={item.links[0]}
            as={Link}
            to={item.links[0]}
            onClick={() => {
              let updatedLinks = links;
              updatedLinks[index].open = !updatedLinks[index].open;
              setLinks(updatedLinks);
            }}
            aria-controls={"example-collapse-" + index}
            aria-expanded={item.open}
          >
            <FontAwesomeIcon
              className="sidebar-icon me-3"
              icon={item.icon}
            ></FontAwesomeIcon>
            {item.title}
            <FontAwesomeIcon
              className="float-end mt-1"
              icon={item.open ? faChevronUp : faChevronDown}
            ></FontAwesomeIcon>
          </Nav.Link>
          <Collapse in={item.open}>
            <div className="bg-white ms-3" id={"example-collapse-" + index}>
              {item.menu.map((menuItem, menuItemIndex) => (
                <Nav.Link
                  key={"menu-item-" + index + "-" + menuItemIndex}
                  eventKey={menuItem.links[0]}
                  as={Link}
                  to={menuItem.links[0]}
                  active={menuItem.links.includes(history.location.pathname)}
                >
                  <FontAwesomeIcon
                    className="sidebar-icon me-3"
                    icon={menuItem.icon}
                  ></FontAwesomeIcon>
                  {menuItem.title}
                </Nav.Link>
              ))}
            </div>
          </Collapse>
        </>
      ) : (
        <Nav.Link
          key={"menu-item-" + index}
          eventKey={item.links[0]}
          as={Link}
          to={item.links[0]}
          active={item.links.includes(history.location.pathname)}
        >
          <FontAwesomeIcon
            className="sidebar-icon me-3"
            icon={item.icon}
          ></FontAwesomeIcon>
          {item.title}
        </Nav.Link>
      )
    );

  return (
    <>
      <aside className="docSidebarContainer">
        <div className="sidebarViewport">
          <div className="sidebar">
            <Nav
              variant="pills"
              defaultActiveKey="/dashboard"
              className="flex-column"
            >
              {showNavlinks()}
            </Nav>
          </div>
        </div>
      </aside>
    </>
  );
}
export default Sidebar;
