import Spinner from "react-bootstrap/Spinner";

function Loader(props) {
  return (
    <div className="text-center" {...props}>
      <Spinner
        id="loader"
        animation="border"
        size="lg"
        variant="primary"
        style={{
          margin: 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          zIndex: 100,
          msTransform: "translateY(-50%)",
          transform: "translateY(-50%)",
        }}
      />
    </div>
  );
}
export default Loader;
