import React from "react";
import Modal from "react-bootstrap/Modal";

const RolePermissionUpdateModal = (props) => {
  const closeModal = () => {
    props.onHide();
  };

  const element_Button = () => (
    <button
      className="btn btn-primary mt-2 me-2 w-100"
      type="button"
      name="reload"
      onClick={(e) => closeModal()}
    >
      OK
    </button>
  );

  return (
    <Modal {...props} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Account Permissions Updated</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Hi There !!! Your Account Permissions has been updated by admin .
          please click the below button
        </p>
        {element_Button()}
      </Modal.Body>
    </Modal>
  );
};
export default RolePermissionUpdateModal;
