import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { useMemo } from "react";

function Footer() {
  const memoyear = useMemo(() => new Date().getFullYear(), []);

  return (
    <>
      <footer>
        <Navbar bg="light" expand="lg" fixed="bottom">
          <Container fluid>
            <div className="text-center w-100">
              © {memoyear} Healthroad.com . All Rights Reserved.
            </div>
          </Container>
        </Navbar>
      </footer>
    </>
  );
}
export default Footer;
