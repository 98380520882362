import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import setAuthToken from "../utils/setAuthToken";
import axios from "axios";
import history from "../utils/history";
import { showSuccessMessage, showErrorMessage } from "../utils/ToasterService";
import API from "../utils/API";

const initialState = {
  loading: null,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  error: null,
  access_token: localStorage.getItem("access_token"),
  permissions: localStorage.getItem("permissions")
    ? JSON.parse(localStorage.getItem("permissions"))
    : [],
  message: null,
};

const baseUrl = `${process.env.REACT_APP_API_URL}/api/auth`;

export const loginUser = createAsyncThunk(
  "LOGIN_USER",
  async ({ username, password }) => {
    const response = await axios.post(baseUrl + "/member-login", {
      emailOrPhoneNumber: username,
      password: password,
    });
    return response.data;
  }
);

export const fetchProfile = createAsyncThunk("FETCH_PROFILE", async () => {
  const response = await API.get("api/auth/get-profile");
  return response.data.data;
});

export const logoutUser = createAsyncThunk("LOGOUT_USER", async () => {
  const response = await API.post("api/auth/logout", {});
  return response.data;
});

export const forgotPasswordUser = createAsyncThunk(
  "FORGOT_PASSWORD",
  async ({ email }) => {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/auth/forgot_password",
      { email: email }
    );
    return response.data;
  }
);

export const resetPasswordUser = createAsyncThunk(
  "RESET_PASSWORD",
  async ({ token, password }) => {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/auth/reset_password",
      { token: token, password: password }
    );
    return response.data;
  }
);

export const changepasswordUser = createAsyncThunk(
  "CHANGE_PASSWORD",
  async ({ password, newPassword, confirmPassword }) => {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/api/auth/change_password",
      {
        password: password,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      }
    );
    return response.data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    rolePermissionUpdated(state, action) {
      state.permissions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        let token = action.payload.token;
        localStorage.setItem("access_token", "" + token);
        localStorage.setItem(
          "permissions",
          JSON.stringify(action.payload.permissions)
        );
        state.access_token = token;
        state.permissions = action.payload.permissions;
        setAuthToken(token);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.access_token = null;
        state.refresh_token = null;
        state.token_expiry_at = null;
        state.user = null;
        state.permissions = [];
        showErrorMessage("Login Failed");
      });

    builder
      .addCase(fetchProfile.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        localStorage.setItem("user", JSON.stringify(action.payload));
        const { from } = history.location.pathname || {
          from: { pathname: "/dashboard" },
        };
        history.navigate(from);
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

    builder
      .addCase(logoutUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token_expiry_at");
        localStorage.removeItem("user");
        localStorage.removeItem("permissions");

        state.access_token = null;
        state.refresh_token = null;
        state.user = null;
        state.token_expiry_at = null;
        state.permissions = [];
        showSuccessMessage("Logout Successful");
        setAuthToken(false);
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        showErrorMessage("Failed to logout");
      });

    builder
      .addCase(forgotPasswordUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPasswordUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        showSuccessMessage("Email Successful Send");
      })
      .addCase(forgotPasswordUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        showErrorMessage(action.error.message);
      });

    builder
      .addCase(changepasswordUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changepasswordUser.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        showSuccessMessage("Password Successfully Changed , Please login");
        history.navigate("/login");
      })
      .addCase(changepasswordUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        showErrorMessage(action.error.message);
      });
  },
});

// Action creators are generated for each case reducer function
export const { rolePermissionUpdated } = authSlice.actions;
export const authReducer = authSlice.reducer;
