import axios from "axios";
import history from "./history";
import { showErrorMessage } from "./ToasterService";

const removeStorage = (calledFrom) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("token_expiry_at");
  localStorage.removeItem("user");
  localStorage.removeItem("permissions");
  showErrorMessage("Session Expired , Please Login");
  window.location.href = "/login";
};

var API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
API.interceptors.request.use(async (req) => {
  if (localStorage.getItem("access_token")) {
    req.headers.Authorization = `${localStorage.getItem("access_token")}`;
  }
  /* const accessTokenExpires = localStorage.getItem("token_expiry_at")
    ? localStorage.getItem("token_expiry_at")
    : new Date();
  let isExpired =
    new Date(accessTokenExpires).getTime() - new Date().getTime() <= 0
      ? true
      : false;
  if (!isExpired) return req;
  if (isExpired) {
    removeStorage("TOKEN_LIFE");
  } */
  return req;
});
API.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log(err);
    if (err && err.response && err.response.status === 500) {
      let msg = err.response.data ? err.response.data.message : err.message;
      showErrorMessage(msg);
    }
    if (err && err.response && err.response.status === 401) {
      removeStorage("API_ERROR");
    }
    if (err && err.response && err.response.status === 403) {
      showErrorMessage(
        "Error 403 Unauthorised : Access Denied - You are not authorised person to access this content"
      );
      history.navigate("/unauthorised");
      return Promise.resolve(null);
    }
    if (err && err.response && err.response.status === 422) {
      if (err.response.data) {
        showErrorMessage(
          err.response.data.message +
            " : " +
            Object.values(err.response.data.errors).join("\n")
        );
      }
    }
    return Promise.reject(err);
  }
);

export default API;
