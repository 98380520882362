import {
  faMessage,
  faBell,
  faDashboard,
  faUsers,
  faList,
  faQuestionCircle,
  faChartLine,
  faUserShield,
  faUserCircle,
  faUtensilSpoon,
  faCookie,
  faHeartPulse,
  faBowlFood,
  faBox,
  faGlobe,
  faArrowsRotate,
  faBuilding,
  faShield,
  faLaptop,
  faUserTie,
  faSquarePollHorizontal,
  faTruckFast,
  faCalculator,
  faTicket,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
const adminMenu = [
  {
    icon: faDashboard,
    title: "Dashboard",
    links: ["/dashboard"],
  },
  {
    icon: faLaptop,
    title: "CRM",
    links: ["#CRM"],
    open: false,
    menu: [
      {
        icon: faUsers,
        title: "Customers",
        links: ["/customers"],
      },
      {
        icon: faChartLine,
        title: "Orders & Subscriptions",
        links: ["/orders", "/subscriptions"],
      },
      {
        icon: faMessage,
        title: "Reviews",
        links: ["/reviews"],
      },
      {
        icon: faSquarePollHorizontal,
        title: "Leadpool",
        links: ["/leadpool"],
      },
      {
        icon: faUserTie,
        title: "Account Managers",
        links: ["/account-managers"],
      },
    ],
  },
  {
    icon: faBowlFood,
    title: "Kitchen",
    links: ["/kitchen"],
  },
  {
    icon: faTruckFast,
    title: "Delivery",
    links: ["/delivery"],
  },
  {
    icon: faBuilding,
    title: "Delivery companies",
    links: ["/delivery_companies"],
  },
  {
    icon: faList,
    title: "Categories",
    links: ["/categories"],
  },
  {
    icon: faHeartPulse,
    title: "Dietary Plans",
    links: ["/dietary-plans"],
  },
  {
    icon: faCookie,
    title: "Nutritions & Menu",
    links: ["#Nutritions"],
    open: false,
    menu: [
      {
        icon: faCookie,
        title: "Ingredients",
        links: ["/ingredients"],
      },
      {
        icon: faUtensilSpoon,
        title: "Recipes",
        links: ["/recipes"],
      },
      {
        icon: faCalculator,
        title: "Calorie Calculation",
        links: ["/calorie-calculation"],
      },
    ],
  },
  /* {
    icon: faBowlFood,
    title: "Meals",
    links: "/meals",
  }, */
  {
    icon: faBox,
    title: "Packages",
    links: ["/packages"],
  },
  {
    icon: faComment,
    title: "Feedback",
    links: ["/feedback"],
  },
  {
    icon: faUserShield,
    title: "User Permissions",
    links: ["/user-permissions"],
  },
  {
    icon: faBell,
    title: "Notify",
    links: ["/notify"],
  },
  {
    icon: faQuestionCircle,
    title: "Ticket Management",
    links: ["/ticket-management"],
  },
  {
    icon: faUserCircle,
    title: "Staff Management",
    links: ["/staff-management"],
  },
  {
    icon: faTicket,
    title: "Coupon Codes",
    links: ["/coupon-code"],
  },
  {
    icon: faGlobe,
    title: "Regions",
    links: ["/regions"],
  },
  {
    icon: faQuestionCircle,
    title: "Activeness",
    links: ["/activeness_questions"],
  },
  {
    icon: faShield,
    title: "Policies",
    links: ["/policies"],
  },
  {
    icon: faMessage,
    title: "Chat",
    links: ["/chat/conversations"],
  },
];
export default adminMenu;
