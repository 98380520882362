import {
  faDashboard,
  faUsers,
  faChartLine,
  faUserTie,
  faArrowsRotate,
  faMessage,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";

const accountManagerMenu = [
  {
    icon: faDashboard,
    title: "Dashboard",
    links: ["/dashboard"],
  },
  {
    icon: faUsers,
    title: "Customers",
    links: ["/customers"],
  },
  {
    icon: faArrowsRotate,
    title: "Subscriptions",
    links: ["/subscriptions"],
  },
  {
    icon: faMessage,
    title: "Reviews",
    links: ["/reviews"],
  },
  {
    icon: faSquarePollHorizontal,
    title: "Leadpool",
    links: ["/leadpool"],
  },
  {
    icon: faUserTie,
    title: "Account Managers",
    links: ["/account-managers"],
  },
  {
    icon: faChartLine,
    title: "Orders",
    links: ["/orders"],
  },
];
export default accountManagerMenu;
