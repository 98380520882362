import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import history from "./history";

function PrivateRoute({ children, canAccess }) {
  const { user: authUser } = useSelector((x) => x.auth);
  if (!authUser) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  if (canAccess && canAccess.length > 0) {
    if (canAccess.includes(authUser.role)) {
      return children;
    } else {
      return <Navigate to="/unauthorised" state={{ from: history.location }} />;
    }
  }

  // authorized so return child components
  return children;
}
export default PrivateRoute;
