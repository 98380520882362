import { lazy } from "react";

const Login = lazy(() => import("../components/Auth/Login/Login"));
const ChangePassword = lazy(
  () => import("../components/Auth/ChangePassword/ChangePassword")
);
const ForgotPassword = lazy(
  () => import("../components/Auth/ForgotPassword/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("../components/Auth/ResetPassword/ResetPassword")
);
const Dashboard = lazy(() => import("../components/Pages/Dashboard/Dashboard"));
const Customers = lazy(
  () => import("../components/Pages/Customers/CustomerList")
);
const CustomersView = lazy(
  () => import("../components/Pages/Customers/CustomerView")
);
const Reports = lazy(() => import("../components/Pages/Reports/Reports"));
const Profile = lazy(() => import("../components/Pages/Profile/Profile"));

const UserManagement = lazy(
  () => import("../components/Pages/UserManagement/UserManagementList")
);
const Notify = lazy(() => import("../components/Pages/Notify/Notify"));
const RolePermissions = lazy(
  () => import("../components/Pages/RolePermissions/RolePermissions")
);
const DietaryPlans = lazy(
  () => import("../components/Pages/DietaryPlans/DietaryPlansList")
);
const Category = lazy(
  () => import("../components/Pages/Category/CategoryList")
);
const FoodGroup = lazy(
  () => import("../components/Pages/FoodGroup/FoodGroupList")
);

const PageNotFound = lazy(
  () => import("../components/ErrorPages/PageNotFound/PageNotFound")
);
const Unauthorised = lazy(
  () => import("../components/ErrorPages/Unauthorised/Unauthorised")
);
const IngredientsList = lazy(
  () => import("../components/Pages/Ingredients/IngredientsList")
);
const RecipesList = lazy(
  () => import("../components/Pages/Recipes/RecipesList")
);
const MealsList = lazy(() => import("../components/Pages/Meals/MealsList"));
const FoodPackageList = lazy(
  () => import("../components/Pages/FoodPackage/FoodPackageList")
);

const CaloryCalculation = lazy(
  () => import("../components/Pages/CaloryCalculation/CaloryCalculation")
);

const SupportTicketsList = lazy(
  () => import("../components/Pages/SupportTickets/SupportTicketsList")
);
const NotificationsList = lazy(
  () => import("../components/Pages/Notifications/NotificationsList")
);
const LeadList = lazy(() => import("../components/Pages/Lead/LeadList"));
const AccountManagersList = lazy(
  () => import("../components/Pages/AccountManagers/AccountManagersList")
);
const ReviewsList = lazy(
  () => import("../components/Pages/Reviews/ReviewsList")
);
const SubscriptionsList = lazy(
  () => import("../components/Pages/Subscriptions/SubscriptionsList")
);
const RegionTimezonesList = lazy(
  () => import("../components/Pages/RegionTimezones/RegionTimezonesList")
);
const OrdersList = lazy(() => import("../components/Pages/Orders/OrdersList"));
const OrdersView = lazy(() => import("../components/Pages/Orders/OrdersView"));
const UserManagementView = lazy(
  () => import("../components/Pages/UserManagement/UserManagementView")
);
const ActivenessQuestionList = lazy(
  () => import("../components/Pages/ActivenessQuestion/ActivenessQuestionList")
);
const DeliveryCompanyList = lazy(
  () => import("../components/Pages/DeliveryCompany/DeliveryCompanyList")
);
const DeliveryList = lazy(
  () => import("../components/Pages/DeliveryList/DeliveryList")
);

const KitchenList = lazy(
  () => import("../components/Pages/KitchenList/KichenList")
);

const PolicyList = lazy(() => import("../components/Pages/Policy/PolicyList"));
const Chat = lazy(() => import("../components/Pages/Chat/Chat"));
const CouponCodeList = lazy(
  () => import("../components/Pages/CouponCode/CouponCodeList")
);
const AllergiesList = lazy(
  () => import("../components/Pages/Allergies/AllergiesList")
);
const FeedbackList = lazy(
  () => import("../components/Pages/Feedback/FeedbackList")
);

const routes = [
  {
    paths: ["/login"],
    element: <Login />,
    isPrivate: false,
    canAccess: [],
  },
  {
    paths: ["/forgot-password"],
    element: <ForgotPassword />,
    isPrivate: false,
    canAccess: [],
  },
  {
    paths: ["/resetpassword/:token"],
    element: <ResetPassword />,
    isPrivate: false,
    canAccess: [],
  },
  {
    paths: ["/change-password"],
    element: <ChangePassword />,
    isPrivate: true,
    canAccess: [],
  },
  {
    paths: ["/profile"],
    element: <Profile />,
    isPrivate: true,
    canAccess: [],
  },
  {
    paths: ["/notifications"],
    element: <NotificationsList />,
    isPrivate: true,
    canAccess: [],
  },
  {
    paths: ["/", "/dashboard"],
    element: <Dashboard />,
    isPrivate: true,
    canAccess: [],
  },
  {
    paths: ["/coupon-code"],
    element: <CouponCodeList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/account-managers"],
    element: <AccountManagersList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "account-manager"],
  },
  {
    paths: ["/subscriptions"],
    element: <SubscriptionsList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "account-manager"],
  },
  {
    paths: ["/orders"],
    element: <OrdersList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "account-manager"],
  },
  {
    paths: [
      "/orders/:id/info",
      "/orders/:id/meals",
      "/orders/:id/deliveries",
      "/orders/:id/subscription",
      "/orders/:id/reviews",
    ],
    element: <OrdersView />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "account-manager"],
  },
  {
    paths: ["/reviews"],
    element: <ReviewsList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "account-manager"],
  },
  {
    paths: ["/leadpool"],
    element: <LeadList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "account-manager"],
  },
  {
    paths: ["/customers"],
    element: <Customers />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "account-manager"],
  },
  {
    paths: [
      "/customers/:id/info",
      "/customers/:id/orders",
      "/customers/:id/subscriptions",
      "/customers/:id/reviews",
      "/customers/:id/login-history",
      "/customers/:id/ticket-management",
    ],
    element: <CustomersView />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "account-manager"],
  },
  {
    paths: [
      "/chat",
      "/chat/conversations",
      "/chat/conversations/:conversation_id",
      "/chat/requests",
      "/chat/requests/:id",
    ],
    element: <Chat />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "customer-support"],
  },
  {
    paths: ["/staff-management"],
    element: <UserManagement />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/staff-management/:id/info"],
    element: <UserManagementView />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/notify"],
    element: <Notify />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/categories"],
    element: <Category />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "nutritionist"],
  },
  {
    paths: ["/food-group"],
    element: <FoodGroup />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "nutritionist"],
  },
  {
    paths: ["/allergies"],
    element: <AllergiesList />,
    isPrivate: true,
    canAccess: [],
    // canAccess: ["admin", "nutritionist"],
  },
  {
    paths: ["/reports"],
    element: <Reports />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/calorie-calculation"],
    element: <CaloryCalculation />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "nutritionist"],
  },
  {
    paths: ["/dietary-plans"],
    element: <DietaryPlans />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "nutritionist"],
  },
  {
    paths: ["/ingredients"],
    element: <IngredientsList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "nutritionist"],
  },
  {
    paths: ["/recipes"],
    element: <RecipesList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "nutritionist"],
  },
  {
    paths: ["/meals"],
    element: <MealsList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "nutritionist"],
  },
  {
    paths: ["/feedback"],
    element: <FeedbackList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/packages"],
    element: <FoodPackageList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "nutritionist"],
  },
  {
    paths: ["/user-permissions"],
    element: <RolePermissions />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/ticket-management"],
    element: <SupportTicketsList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "customer-support"],
  },
  {
    paths: ["/activeness_questions"],
    element: <ActivenessQuestionList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/delivery_companies"],
    element: <DeliveryCompanyList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "delivery-manager"],
  },
  {
    paths: ["/delivery"],
    element: <DeliveryList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "delivery-manager"],
  },
  {
    paths: ["/kitchen"],
    element: <KitchenList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin", "delivery-manager"],
  },
  {
    paths: ["/regions"],
    element: <RegionTimezonesList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/policies"],
    element: <PolicyList />,
    isPrivate: true,
    canAccess: [],
    //canAccess: ["admin"],
  },
  {
    paths: ["/notifications"],
    element: <NotificationsList />,
    isPrivate: true,
    canAccess: [],
  },
  {
    paths: ["/unauthorised"],
    element: <Unauthorised />,
    isPrivate: false,
    canAccess: [],
  },
  {
    paths: ["*"],
    element: <PageNotFound />,
    isPrivate: false,
    canAccess: [],
  },
];

export default routes;
