import { Button } from "react-bootstrap";

const RenderError = ({ error, resetErrorBoundary }) => {
  const style_fs100 = {
    fontSize: "7.6923076923rem",
  };

  const style_textFade = {
    color: "#7e8299",
  };

  const style_errorPage = {
    backgroundImage: `url('/bg-18.png')`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "80vh",
  };

  return (
    <section className="error-page" style={style_errorPage}>
      <div className="container">
        <div className="row align-items-center justify-content-center text-center">
          <div className="mt-5 col-lg-7 col-md-10 col-12">
            <div className="rounded30 p-50">
              <h1 style={style_fs100}>500</h1>
              <h1>Uh-Ah</h1>
              <h3 style={style_textFade}>Internal Server Error !</h3>
              <div className="my-3">
                <pre>{error ? error.message : ""}</pre>
                <Button variant="primary" onClick={resetErrorBoundary}>
                  Try again
                </Button>
              </div>
              <h5 style={style_textFade} className="mb-2">
                -- OR --
              </h5>
              <h4 style={style_textFade}>Please try after some time</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default RenderError;
