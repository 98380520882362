import {
  faArrowsRotate,
  faDashboard,
  faTruckFast,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

const deliveryManagerMenu = [
  {
    icon: faDashboard,
    title: "Dashboard",
    links: ["/dashboard"],
  },
  {
    icon: faArrowsRotate,
    title: "Subscriptions",
    links: ["/subscriptions"],
  },
  {
    icon: faTruckFast,
    title: "Delivery",
    links: ["/delivery"],
  },
  {
    icon: faBuilding,
    title: "Delivery Companies",
    links: ["/delivery_companies"],
  },
];
export default deliveryManagerMenu;
