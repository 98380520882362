import moment from "moment";
export function getLocalTime(utcTime, displayFormat = "DD-MM-YYYY hh:mm A") {
  return moment(utcTime).local().format(displayFormat);
}

let curdate = new Date();
export function getForattedTime(utcTime, displayFormat = "hh:mm A") {
  let curdate2 =
    moment(curdate).format("DD-MM-YYYY") + "T" + utcTime + ":00.000";
  return moment(curdate2, "DD-MM-YYYYTHH:mm:ss.000").format(displayFormat);
}
